import React, {useEffect, useState} from "react";

import {CDSNotification} from "@ciscodesignsystems/cds-react-notification";

import usePersistedBannerStates from "hooks/usePersistedBannerState";
import tdrClient from "apis/tdr/tdrClient";
import useConfig from "hooks/useConfig";

import {mapEventTypeToStatus, filterItemsByRegion} from "./utils";

import type {BannerItem, BannerStates} from "types/bannerState";
import type {BannerRequestDefault} from "./types";

interface MaybeMultiBannerProps {
  bannerStates: BannerStates[];
  banners: BannerItem[];
  setBannerStates: (newBannerStates: BannerStates[]) => void;
}

const bannerRequestInit: BannerRequestDefault = {
  isLoading: false,
  error: null,
  data: null,
};

const isClosedInStorage =
  (storedBanners: BannerStates[]) => (banner: BannerItem) =>
    storedBanners.find((storedBanner) => storedBanner.id === banner.id)
      ?.closed === false;

const onCloseBanner =
  (
    bannerStates: BannerStates[],
    banner: BannerItem,
    setBannerStates: (state: BannerStates[]) => void,
  ) =>
  () => {
    const updatedData = bannerStates.map((storedBanner) => {
      if (storedBanner.id === banner?.id) {
        return {...storedBanner, closed: true};
      }
      return storedBanner;
    });
    setBannerStates(updatedData);
  };

const MaybeMultiBanner = ({
  bannerStates,
  banners,
  setBannerStates,
}: MaybeMultiBannerProps) => {
  return bannerStates.some(
    (banner: BannerStates) => banner.closed === false,
  ) ? (
    <CDSNotification
      style={{margin: 20}}
      aria-label="Notification"
      grouped
      onDismissAll={() => {
        const updatedData = bannerStates.map((storedBanner: BannerStates) => ({
          ...storedBanner,
          closed: true,
        }));
        setBannerStates(updatedData);
      }}
      product
      title="You have multiple notifications"
    >
      <CDSNotification.Group>
        {banners?.filter(isClosedInStorage(bannerStates))?.map((banner) => (
          <CDSNotification.Item
            key={banner?.id}
            title={banner?.title}
            status={mapEventTypeToStatus(banner?.event_type)}
            onClose={onCloseBanner(bannerStates, banner, setBannerStates)}
          >
            {banner?.status_updates.slice(-1)?.[0]?.public_description}
          </CDSNotification.Item>
        ))}
      </CDSNotification.Group>
    </CDSNotification>
  ) : null;
};

const SitewideBanner = () => {
  const [bannerStates, setBannerStates] = usePersistedBannerStates();
  const {config} = useConfig();
  const {region} = config;
  const [bannerRequest, setBannerRequest] =
    useState<BannerRequestDefault>(bannerRequestInit);

  useEffect(
    () => {
      setBannerRequest({
        ...bannerRequestInit,
        isLoading: true,
      });

      tdrClient
        .get("/api/tdr/proxy")
        .then(({data}) => {
          const maybeFilteredItems = filterItemsByRegion(data?.items, region);

          if (bannerStates?.length === 0) {
            setBannerStates(
              maybeFilteredItems.map((banner: BannerItem) => ({
                id: banner.id,
                end_timestamp: banner.end_timestamp,
                closed: false,
              })),
            );
          } else {
            const storeData = bannerStates.map((storedBanner: BannerStates) => {
              const banner = maybeFilteredItems.find(
                (banner) => banner.id === storedBanner.id,
              );
              return banner
                ? {...storedBanner, end_timestamp: banner.end_timestamp}
                : storedBanner;
            });

            setBannerStates(storeData);
          }

          setBannerRequest({
            ...bannerRequestInit,
            isLoading: false,
            data: {
              ...data,
              items: maybeFilteredItems.map((bannerData: BannerItem) => ({
                ...bannerData,
                closed:
                  bannerStates.find(
                    (banner: BannerStates) => banner.id === bannerData.id,
                  )?.closed ?? false,
              })),
            },
          });
        })
        .catch((error) => {
          setBannerStates([
            ...bannerStates,
            {id: "1", closed: false, end_timestamp: ""},
          ]);

          setBannerRequest({
            ...bannerRequestInit,
            isLoading: false,
            error,
            data: {
              start: 0,
              stop: 0,
              total: 1,
              items: [
                {
                  id: "1",
                  title: "We were unable to get notifications",
                  event_type: "performance",
                  product_id: "",
                  scheduled_end_timestamp: null,
                  creation_timestamp: "",
                  start_timestamp: "",
                  end_timestamp: "",
                  status_updates: [
                    {
                      id: "1",
                      creation_timestamp: "",
                      status_type: "investigating",
                      event_id: "1",
                      public_description:
                        "Please contact technical support for assistance if this continues.",
                    },
                  ],
                },
              ],
            },
          });
        });
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      // Run only on mount. Exhuastive deps will cause a loop
    ],
  );

  const banners =
    bannerRequest.data?.items?.filter(isClosedInStorage(bannerStates)) ?? [];

  if (banners?.length === 0 || bannerRequest.isLoading) return null;

  return banners?.length > 0 && banners?.length <= 3 ? (
    <div style={{margin: 20}}>
      {banners.map((banner) => (
        <CDSNotification
          style={{marginBottom: "1rem"}}
          banner
          key={banner.id}
          title={banner.title}
          status={mapEventTypeToStatus(banner.event_type)}
          onClose={onCloseBanner(bannerStates, banner, setBannerStates)}
        >
          {banner?.status_updates?.slice(-1)[0].public_description}
        </CDSNotification>
      ))}
    </div>
  ) : (
    <MaybeMultiBanner
      bannerStates={bannerStates}
      banners={banners}
      setBannerStates={setBannerStates}
    />
  );
};

export default SitewideBanner;
