export type BannerStates = {
  id: string;
  end_timestamp: string;
  closed: boolean;
};

export type BannerComponent = {
  id: string;
  product_id: string;
  name: string;
  region_id: string;
  status: string;
  region: {
    id: string;
    name: string;
  };
};

type BannerProductComponent = BannerComponent & {
  dependee_components?: Array<BannerComponent>;
};

export type BannerItem = {
  id: string;
  title: string;
  creation_timestamp: string;
  event_type: string;
  product_id: string;
  start_timestamp: string;
  end_timestamp: string;
  scheduled_end_timestamp: string | null;
  product?: {
    id: string;
    name: string;
    components: Array<BannerProductComponent>;
  };
  components?: Array<BannerComponent>;
  status_updates: Array<{
    id: string;
    creation_timestamp: string;
    status_type: string;
    event_id: string;
    public_description: string;
  }>;
};

export type BannerData = {
  start: number;
  stop: number;
  total: number;
  items: Array<BannerItem>;
};

export const LS_KEY = "xdr-user-banner-preferences" as const;
